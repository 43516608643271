import { Locale, type LocalizedString } from "../Locale.js";
import { Service, type ServiceWithPage } from "../Service.js";

export const servicesNameMap = {
	[Locale.cs]: "Služby",
	[Locale.en]: "Services",
} satisfies LocalizedString;

export const serviceCategoryNameMap: Record<Service, LocalizedString> = {
	[Service.Integration]: {
		[Locale.cs]: "Integrace",
		[Locale.en]: "Integration",
	},
	[Service.Consultation]: {
		[Locale.cs]: "Konzultace",
		[Locale.en]: "Consultation",
	},
	[Service.Service]: {
		[Locale.cs]: "Servis a podpora",
		[Locale.en]: "Service and Support",
	},
	[Service.Financing]: {
		[Locale.cs]: "Financování",
		[Locale.en]: "Financing",
	},
};

export const serviceWithPageNameMap: Record<ServiceWithPage, LocalizedString> = {
	[Service.Integration]: serviceCategoryNameMap[Service.Integration],
	[Service.Service]: serviceCategoryNameMap[Service.Service],
};
